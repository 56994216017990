import React from "react"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import { Box, Grid, Heading } from "grommet"
import { Link } from "gatsby"
import { colors } from "../../theme";
import Avatar from "../UI/Avatar";
import styled from 'styled-components'

const GET_TUTORS = gql`
  query getTutors {
    findUsers(type: "tutor") {
      users {
        _id
        name
        email
        avatar {
          url
        }
      }
    }
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > :first-child {
    margin-bottom: 10px;
  }
`

const TutorsList = () => {
  const { loading, error, data } = useQuery(GET_TUTORS, {
    fetchPolicy: "network-only",
  })

  if (loading || error) {
    return null
  }
  const tutors = data.findUsers.users

  return (
    <Grid
      columns={{ count: "fill", size: "220px" }}
      rows={"220px"}
      gap="small">
      {tutors.map(tutor => (
        <Link to={`/tutor/${tutor._id}`}>
          <Box
            key={tutor._id}
            round="small"
            background="white"
            elevation="medium"
            pad="small"
          >
            <Wrapper>
              <Avatar user={tutor} size='90px' />
              <Heading level="5" color={colors.indigo} margin="xsmall">{tutor.name}</Heading>
            </Wrapper>
          </Box>
        </Link>
      ))}
    </Grid>
  )
}

export default TutorsList
