import React from "react"

import TutorsList from "../components/TutorsList"

const TutorsPage = () => {
  if (typeof window === "undefined") return null
  return <TutorsList />
}

export default TutorsPage
